import * as React from "react"

const BackEndIcon = () => (
  <svg
    viewBox="0 0 512 512"
    className="pc:w-28 sp:w-16 mx-auto fill-current text-brand-primary"
  >
    <g>
      <path
        d="M459.991,82.262c-5.818-13.388-15.866-24.916-28.61-34.854c-19.179-14.896-44.758-26.522-74.674-34.691
		C326.789,4.575,292.498,0,256,0c-55.622,0.036-106.071,10.548-143.848,28.374c-18.889,8.959-34.692,19.76-46.31,32.713
		c-5.791,6.48-10.52,13.542-13.832,21.176c-3.304,7.634-5.146,15.866-5.138,24.29V405.44c-0.008,8.423,1.834,16.665,5.138,24.29
		c5.818,13.388,15.866,24.915,28.61,34.863c19.18,14.896,44.757,26.523,74.675,34.692C185.211,507.425,219.503,511.992,256,512
		c55.632-0.046,106.07-10.546,143.848-28.383c18.888-8.958,34.691-19.751,46.309-32.712c5.792-6.472,10.52-13.534,13.833-21.176
		c3.304-7.624,5.146-15.867,5.137-24.29V106.552C465.137,98.128,463.295,89.896,459.991,82.262z M436.282,405.44
		c-0.009,4.293-0.889,8.478-2.768,12.843c-3.268,7.597-9.849,15.766-19.861,23.545c-14.967,11.7-37.369,22.257-64.526,29.626
		c-27.158,7.388-59.054,11.709-93.128,11.7c-51.909,0.046-98.818-10.084-131.503-25.606c-16.357-7.715-29.064-16.774-37.142-25.86
		c-4.066-4.538-6.989-9.058-8.868-13.406c-1.88-4.366-2.76-8.55-2.769-12.843v-49.36c16.684,15.25,40.111,27.43,68.13,36.435
		c32.304,10.32,70.789,16.247,112.152,16.256c55.16-0.027,105.164-10.493,142.306-28.029c14.931-7.08,27.693-15.368,37.976-24.798
		V405.44z M436.282,321.198c-2.051,4.402-4.983,8.814-8.94,13.27c-12.78,14.396-35.917,27.948-65.77,37.442
		c-29.844,9.548-66.287,15.23-105.572,15.222c-52.372,0.027-99.726-10.14-133.046-25.95c-16.674-7.87-29.763-17.137-38.294-26.714
		c-3.958-4.456-6.89-8.868-8.942-13.26v-64.754c16.684,15.249,40.111,27.43,68.13,36.434
		c32.304,10.311,70.789,16.238,112.152,16.248c55.16-0.028,105.164-10.492,142.306-28.03c14.931-7.08,27.693-15.357,37.976-24.788
		V321.198z M436.282,221.572c-2.051,4.402-4.983,8.813-8.94,13.261c-12.78,14.395-35.917,27.956-65.77,37.441
		c-29.844,9.549-66.287,15.231-105.572,15.222c-52.372,0.028-99.726-10.138-133.046-25.941
		c-16.674-7.87-29.763-17.146-38.294-26.722c-3.958-4.448-6.89-8.859-8.942-13.261v-64.754c16.684,15.258,40.111,27.43,68.13,36.443
		c32.304,10.32,70.789,16.247,112.152,16.247c55.16-0.027,105.164-10.484,142.306-28.029c14.931-7.08,27.693-15.358,37.976-24.789
		V221.572z M436.282,121.936c-2.051,4.403-4.983,8.823-8.94,13.271c-12.78,14.405-35.917,27.956-65.77,37.451
		c-29.844,9.548-66.287,15.23-105.572,15.222c-52.372,0.027-99.726-10.14-133.046-25.95c-16.674-7.879-29.763-17.147-38.294-26.722
		c-3.958-4.448-6.89-8.868-8.942-13.262v-15.394c0.01-4.294,0.89-8.469,2.769-12.835c3.268-7.607,9.848-15.766,19.86-23.554
		c14.968-11.7,37.369-22.256,64.526-29.617c27.158-7.398,59.053-11.709,93.127-11.7c51.91-0.046,98.819,10.085,131.504,25.606
		c16.356,7.706,29.054,16.765,37.142,25.86c4.066,4.538,6.988,9.05,8.868,13.406c1.878,4.366,2.758,8.541,2.768,12.835V121.936z"
      ></path>
    </g>
  </svg>
)

export default BackEndIcon
