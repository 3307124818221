import * as React from "react"

const Description = ({ text }) => {
  return (
    <h3 className="pc:mt-8 sp:mt-5 pc:text-center whitespace-pre-line">
      {text}
    </h3>
  )
}

export default Description
