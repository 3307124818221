import * as React from "react"

const MarketingIcon = () => (
  <svg
    id="bd3d7bff-83b6-4602-8d29-a1c6e302fae5"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 764.11421 484"
    className="pc:w-60 sp:w-40"
  >
    <path
      d="M976.32077,525H681.32883a5.74275,5.74275,0,0,1-5.73633-5.73633V213.73633A5.74275,5.74275,0,0,1,681.32883,208H976.32077a5.74274,5.74274,0,0,1,5.73633,5.73633V519.26367A5.74274,5.74274,0,0,1,976.32077,525ZM681.32883,210a3.74061,3.74061,0,0,0-3.73633,3.73633V519.26367A3.74061,3.74061,0,0,0,681.32883,523H976.32077a3.74061,3.74061,0,0,0,3.73633-3.73633V213.73633A3.74061,3.74061,0,0,0,976.32077,210Z"
      transform="translate(-217.9429 -208)"
      fill="#3f3d56"
    />
    <path
      d="M884.42759,336.40637a63.22083,63.22083,0,1,1-55.6028-33.11893c.50149,0,1.00124.0074,1.5.019l-.66847-27.00551c-.27743-.00249-.55349-.01054-.83148-.01054a90.18361,90.18361,0,1,0,77.35045,43.77229Z"
      transform="translate(-217.9429 -208)"
      fill="#5cf2e8"
    />
    <path
      d="M884.42759,336.40637l21.74765-16.34368a90.1564,90.1564,0,0,0-76.519-43.76176l.66847,27.00551A63.20326,63.20326,0,0,1,884.42759,336.40637Z"
      transform="translate(-217.9429 -208)"
      fill="#e6e6e6"
    />
    <rect
      x="889.24044"
      y="437.96902"
      width="1.57092"
      height="45.01185"
      transform="translate(-284.28049 551.72758) rotate(-44.73106)"
      fill="#3f3d56"
    />
    <rect
      x="862.2521"
      y="267.4178"
      width="45.01191"
      height="1.57131"
      transform="translate(0.08452 700.67217) rotate(-60.7131)"
      fill="#3f3d56"
    />
    <path
      d="M929.56934,488.81543h-46.75a3.13811,3.13811,0,0,1-3.13477-3.13379v-6.584a3.13811,3.13811,0,0,1,3.13477-3.13379h46.75a3.13811,3.13811,0,0,1,3.13476,3.13379v6.584A3.13811,3.13811,0,0,1,929.56934,488.81543Z"
      transform="translate(-217.9429 -208)"
      fill="#5cf2e8"
    />
    <path
      d="M917.71777,249.13477h-46.751A3.13759,3.13759,0,0,1,867.833,246.001V239.4165a3.13759,3.13759,0,0,1,3.13379-3.13379h46.751a3.13759,3.13759,0,0,1,3.13379,3.13379V246.001A3.13759,3.13759,0,0,1,917.71777,249.13477Z"
      transform="translate(-217.9429 -208)"
      fill="#e6e6e6"
    />
    <path
      d="M628.437,353.87256H295.06785a5.47106,5.47106,0,0,1-5.46485-5.46485V214.46484A5.47106,5.47106,0,0,1,295.06785,209H628.437a5.471,5.471,0,0,1,5.4646,5.46484V348.40771A5.471,5.471,0,0,1,628.437,353.87256ZM295.06785,211a3.46861,3.46861,0,0,0-3.46485,3.46484V348.40771a3.46861,3.46861,0,0,0,3.46485,3.46485H628.437a3.46856,3.46856,0,0,0,3.4646-3.46485V214.46484A3.46856,3.46856,0,0,0,628.437,211Z"
      transform="translate(-217.9429 -208)"
      fill="#3f3d56"
    />
    <path
      d="M425.02,320.63086H392.72043a3.48484,3.48484,0,0,1-3.43237-2.9209,135.67747,135.67747,0,0,1,0-41.29394,3.48484,3.48484,0,0,1,3.43237-2.9209H425.02a3.44107,3.44107,0,0,1,2.718,1.31689,3.50035,3.50035,0,0,1,.6543,3.00147,82.89377,82.89377,0,0,0-.00025,38.499,3.49941,3.49941,0,0,1-.65405,3.00146A3.441,3.441,0,0,1,425.02,320.63086Z"
      transform="translate(-217.9429 -208)"
      fill="#e6e6e6"
    />
    <path
      d="M533.16477,320.63086H500.865a3.48542,3.48542,0,0,1-3.43237-2.9209,135.67747,135.67747,0,0,1,0-41.29394,3.48542,3.48542,0,0,1,3.43237-2.9209h32.29981a3.4401,3.4401,0,0,1,2.718,1.31738,3.49832,3.49832,0,0,1,.65381,3.001,82.89684,82.89684,0,0,0,0,38.499,3.49832,3.49832,0,0,1-.65381,3.001A3.4401,3.4401,0,0,1,533.16477,320.63086Z"
      transform="translate(-217.9429 -208)"
      fill="#e6e6e6"
    />
    <path
      d="M370.08054,289.37744H337.781a3.48484,3.48484,0,0,1-3.43237-2.9209,135.67683,135.67683,0,0,1,0-41.29394,3.48484,3.48484,0,0,1,3.43237-2.9209h32.29956a3.44108,3.44108,0,0,1,2.718,1.31689,3.50032,3.50032,0,0,1,.65429,3.00147,82.8875,82.8875,0,0,0-.00024,38.499,3.49943,3.49943,0,0,1-.654,3.00147A3.44108,3.44108,0,0,1,370.08054,289.37744Z"
      transform="translate(-217.9429 -208)"
      fill="#5cf2e8"
    />
    <path
      d="M479.31369,289.37744h-32.2998a3.44008,3.44008,0,0,1-2.718-1.31738,3.498,3.498,0,0,1-.65381-3.001,82.8939,82.8939,0,0,0-.00024-38.499,3.49886,3.49886,0,0,1,.654-3.001,3.44008,3.44008,0,0,1,2.718-1.31738h32.2998a3.48459,3.48459,0,0,1,3.43213,2.9209,135.67683,135.67683,0,0,1,0,41.29394A3.48459,3.48459,0,0,1,479.31369,289.37744Z"
      transform="translate(-217.9429 -208)"
      fill="#5cf2e8"
    />
    <path
      d="M585.72385,289.37744h-32.2998a3.44007,3.44007,0,0,1-2.718-1.31738,3.498,3.498,0,0,1-.65381-3.001,82.8939,82.8939,0,0,0-.00024-38.499,3.49886,3.49886,0,0,1,.65405-3.001,3.44007,3.44007,0,0,1,2.718-1.31738h32.2998a3.48541,3.48541,0,0,1,3.43237,2.9209,135.69275,135.69275,0,0,1,0,41.29394A3.48541,3.48541,0,0,1,585.72385,289.37744Z"
      transform="translate(-217.9429 -208)"
      fill="#ff6584"
    />
    <path
      d="M405.88006,527.46578a10.74271,10.74271,0,0,1-7.84208-14.48617l-44.3346-95.1913,22.49073-6.321,36.54671,95.67388a10.80091,10.80091,0,0,1-6.86076,20.32454Z"
      transform="translate(-217.9429 -208)"
      fill="#a0616a"
    />
    <polygon
      points="102.079 471.364 114.339 471.364 120.171 424.076 102.077 424.076 102.079 471.364"
      fill="#a0616a"
    />
    <path
      d="M317.39477,675.86065H355.9255a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H332.28163a14.88686,14.88686,0,0,1-14.88686-14.88686v0A0,0,0,0,1,317.39477,675.86065Z"
      transform="translate(455.40856 1158.5928) rotate(179.99738)"
      fill="#2f2e41"
    />
    <polygon
      points="38.354 458.148 49.623 462.975 73.605 421.804 56.972 414.68 38.354 458.148"
      fill="#a0616a"
    />
    <path
      d="M250.77425,668.87755H289.305a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H265.66111a14.88686,14.88686,0,0,1-14.88686-14.88686v0A0,0,0,0,1,250.77425,668.87755Z"
      transform="translate(34.04329 1196.33579) rotate(-156.81376)"
      fill="#2f2e41"
    />
    <circle cx="132.39046" cy="140.56951" r="24.56103" fill="#a0616a" />
    <path
      d="M332.58921,667.80279l-13.4961-2.57129a4.47356,4.47356,0,0,1-3.63452-4.87891l8.94946-87.25879a3.5,3.5,0,0,0-6.699-1.73535l-39.07129,91.167a4.50037,4.50037,0,0,1-5.96387,2.33887l-10.9768-4.87891a4.48233,4.48233,0,0,1-2.44166-5.53515l26.72217-80.167a3.49915,3.49915,0,0,0,.15528-.69434l8.67407-73.24707a4.5,4.5,0,0,1,2.77514-3.64062l27.38843-11.126a4.50061,4.50061,0,0,1,4.83325.94433l34.57032,33.66114a4.4819,4.4819,0,0,1,1.27466,4.10058L337.84506,664.25884a4.51655,4.51655,0,0,1-4.417,3.62305A4.46966,4.46966,0,0,1,332.58921,667.80279Z"
      transform="translate(-217.9429 -208)"
      fill="#2f2e41"
    />
    <path
      d="M317.203,504.38091c-4.78466-5.54-8.27661-8.05468-10.66479-7.6582-2.36963.38281-3.73657,3.69336-5.18408,7.19824-1.97559,4.78418-4.21485,10.207-9.73438,10.63086a4.64707,4.64707,0,0,1-4.56225-1.87012c-4.34156-6.457,6.072-27.82421,7.02343-29.74414L293.59262,381.877a4.48919,4.48919,0,0,1,2.353-3.98536c28.6167-15.43164,67.25708,11.40625,74.71216,16.94434h0a4.48819,4.48819,0,0,1,1.81055,3.84863c-.89966,16.92578-5.387,102.32032-5.387,121.36914,0,5.27344-1.81885,8.624-5.40625,9.958a10.78141,10.78141,0,0,1-3.77441.61914C346.95493,530.63091,329.02768,518.07232,317.203,504.38091Z"
      transform="translate(-217.9429 -208)"
      fill="#ccc"
    />
    <path
      d="M357.139,455.30865a4.45046,4.45046,0,0,1-2.02271-3.56641l-1.93847-45.26562a11.74737,11.74737,0,0,1,22.71191-4.69141l14.50733,38.0166a4.50482,4.50482,0,0,1-2.59986,5.80859L361.2164,455.754a4.4554,4.4554,0,0,1-4.07739-.44531Z"
      transform="translate(-217.9429 -208)"
      fill="#ccc"
    />
    <path
      d="M289.59176,526.03682a10.74266,10.74266,0,0,1-1.72626-16.38193l-4.69019-104.90444,23.2018,2.732-2.69338,102.38114a10.80091,10.80091,0,0,1-14.092,16.17324Z"
      transform="translate(-217.9429 -208)"
      fill="#a0616a"
    />
    <path
      d="M273.48056,432.68462a4.45084,4.45084,0,0,1-.61865-4.05371l14.32764-42.98242a11.74725,11.74725,0,0,1,22.89184,3.71484v40.69043a4.50508,4.50508,0,0,1-4.5,4.5H277.1312A4.45274,4.45274,0,0,1,273.48056,432.68462Z"
      transform="translate(-217.9429 -208)"
      fill="#ccc"
    />
    <path
      d="M334.47231,365.08922a4.85259,4.85259,0,0,1-6.351-.31653c-5.26394-4.9204-9.70648-10.80395-11.95477-17.618-2.57622-7.80794-1.91812-16.95277,3.01544-23.53006,4.1706-5.56013,10.772-8.70128,17.27427-11.15688a28.2713,28.2713,0,0,1,7.96-2.06728,10.93663,10.93663,0,0,1,7.75447,2.17748,6.86771,6.86771,0,0,1,2.27546,7.397,25.84688,25.84688,0,0,1,11.80136-6.10508,14.26707,14.26707,0,0,1,8.44017.34976c3.77088,1.55077,6.22036,5.33854,7.34911,9.25649a16.72441,16.72441,0,0,1,.21826,9.20913,11.14649,11.14649,0,0,1-5.70595,7.03232c-3.45,1.62536-7.54423,1.01187-11.15158-.22549s-7.02752-3.06328-10.75861-3.85259-8.039-.32877-10.67913,2.42332c-1.85923,1.93808-2.5913,4.66436-3.25629,7.26641l-3.45876,13.53391a16.88047,16.88047,0,0,1-2.15668,5.57368A3.3326,3.3326,0,0,1,334.47231,365.08922Z"
      transform="translate(-217.9429 -208)"
      fill="#2f2e41"
    />
    <path
      d="M434.08066,560.37744h-32.2998a3.485,3.485,0,0,1-3.43225-2.9209,135.67683,135.67683,0,0,1,0-41.29394,3.485,3.485,0,0,1,3.43225-2.9209h32.2998a3.44047,3.44047,0,0,1,2.71814,1.31738,3.49906,3.49906,0,0,1,.65381,3.001,82.8971,82.8971,0,0,0,0,38.499,3.49906,3.49906,0,0,1-.65381,3.001A3.44047,3.44047,0,0,1,434.08066,560.37744Z"
      transform="translate(-217.9429 -208)"
      fill="#5cf2e8"
    />
    <path
      d="M379.9429,692h-161a1,1,0,0,1,0-2h161a1,1,0,0,1,0,2Z"
      transform="translate(-217.9429 -208)"
      fill="#3f3d56"
    />
  </svg>
)

export default MarketingIcon
