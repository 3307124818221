import * as React from "react"

const Ses = () => {
  return (
    <ul className="pc:grid pc:grid-cols-3 pc:gap-y-8 border border-gray-dark p-8">
      <li className="pc:text-center font-bold">・アパレルECサイトデザイン</li>
      <li className="pc:text-center sp:mt-3 font-bold">・ゲーム開発</li>
      <li className="pc:text-center sp:mt-3 font-bold">・ソフトウェア開発</li>
      <li className="pc:text-center sp:mt-3 font-bold">
        ・官公庁システム開発等
      </li>
      <li className="pc:text-center sp:mt-3 font-bold">・企業のWeb広告</li>
      <li className="pc:text-center sp:mt-3 font-bold">
        ・美容院のWebサイト、採用サイト
      </li>
      <li className="pc:text-center sp:mt-3 font-bold">
        ・アパレル会社のECサイト
      </li>
      <li className="pc:text-center sp:mt-3 font-bold">・ソーシャルゲーム</li>
      <li className="pc:text-center sp:mt-3 font-bold">・決済や配車アプリ</li>
      <li className="pc:text-center sp:mt-3 font-bold">
        ・有名雑誌のWeb版の制作等...
      </li>
      <li className="pc:text-center sp:mt-3 font-bold">その他多数</li>
    </ul>
  )
}

export default Ses
