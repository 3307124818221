import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Hero from "../../ui/Hero"
import { SubTitle } from "../../ui/Title"
import Description from "./Description"
import Education from "./education/Education"
import Ses from "./ses/Ses"
import Development from "./development/Development"
import Recruitment from "./recruitment/Recruitment"

const Root = () => {
  return (
    <>
      <Hero title="Service">
        <StaticImage
          src="../../../images/home/service.jpg"
          className="w-full sp:h-36"
          width={400}
          quality={95}
          alt="Service"
        />
      </Hero>
      <section className="pc:max-w-5xl pc:mx-auto pc:mt-40 sp:mt-24 sp:px-4 text-black">
        <SubTitle title="教育" />
        <Description
          text={`IT業界未経験者をWebエンジニア/Webクリエイターに育成します。\n
          弊社では未経験でも活躍できるために、確実な技術力を育てることにフォーカスし人材教育を行っています。\n
          スクールや授業の講義形式ではなく課題形式として、個人の能力を伸ばし、同時に主体性をつける力を伸ばしています。\n
          また現場で活躍する技術者がメンターをしており、現場の生きた経験を届け育成に繋げています。`}
        />
        <div className="pc:mt-20 sp:mt-12">
          <Education />
        </div>
      </section>
      <section className="pc:max-w-5xl pc:mx-auto pc:mt-40 sp:mt-24 sp:px-4 text-black">
        <SubTitle title="SES" />
        <Description
          text={`お客様先にエンジニア、クリエイターが常駐し、技術支援するSES（システムエンジニアリングサービス）事業を展開しております。\n
          ITスキルだけではなく提案力や協調性もあるエンジニアやクリエイターがおります。\n
          お客様のニーズに即したエンジニア・クリエイターをご提案します。`}
        />
        <div className="pc:mt-20 sp:mt-12">
          <Ses />
        </div>
      </section>
      <section className="pc:max-w-5xl pc:mx-auto pc:mt-40 sp:mt-24 sp:px-4 text-black">
        <SubTitle title="受託" />
        <Description
          text={`WEBサイト、WEBシステムのUI/UXデザイン、コーディング、ディレクションからデザイン、モーションデザイン、背景デザイン、関連商材デザインなどデザイン業務に関する幅広いご依頼も、フロントエンドからバックエンドの開発を一貫して対応可能です。\n
          美容サロンを中心にIT技術サービスのご依頼を頂いております。`}
        />
        <div className="pc:mt-20 sp:mt-12">
          <Development />
        </div>
        <div className="pc:mt-20 sp:mt-14">
          <p className="mb-3 font-bold">関連会社サロンHP</p>
          <StaticImage
            src="../../../images/service/salon.png"
            className="w-full"
            quality={95}
            alt="サロン"
          />
        </div>
      </section>
      <section className="pc:max-w-5xl pc:mx-auto pc:mt-40 sp:mt-24 sp:px-4 text-black">
        <SubTitle title="採用コンサルティング" />
        <Description
          text={`採用の成功を共に目指し、お客様の採用ニーズに合わせた戦略を策定・設計し、全面的にバックアップします。\n
          ノンコア業務の代行はもちろん、採用計画のご相談から面接代行まで、どのフェーズでもサポート可能です。\n
          予算や費用対効果を検討しながら、採用活動のパフォーマンスを最大化するフローをご提案いたします。`}
        />
        <div className="pc:mt-20 sp:mt-12">
          <Recruitment />
        </div>
      </section>
    </>
  )
}

export default Root
