import * as React from "react"

const MaintenanceIcon = () => (
  <svg
    id="b5d1da7b-a9c6-4711-8d73-fa7937ec989e"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 888 710.80704"
    className="pc:w-60 sp:w-40"
  >
    <title>server_cluster</title>
    <path
      d="M600.93555,699.40352a260.06372,260.06372,0,1,1,183.89355-76.1709A258.36518,258.36518,0,0,1,600.93555,699.40352Zm0-518.12891c-142.29688,0-258.06446,115.76758-258.06446,258.06494s115.76758,258.064,258.06446,258.064S859,581.63692,859,439.33955,743.23242,181.27461,600.93555,181.27461Z"
      transform="translate(-156 -94.59648)"
      fill="#ccc"
    />
    <rect
      x="469"
      y="509.90391"
      width="262"
      height="195"
      transform="translate(1051.40391 -87.19257) rotate(90)"
      fill="#2f2e41"
    />
    <rect x="313" y="373.80743" width="262" height="104" fill="#3f3d56" />
    <rect x="313" y="489.80743" width="262" height="104" fill="#3f3d56" />
    <rect x="313" y="605.80743" width="262" height="104" fill="#3f3d56" />
    <rect x="313" y="417.90372" width="262" height="16" fill="#f54ea2" />
    <rect x="313" y="533.90372" width="262" height="16" fill="#f54ea2" />
    <rect x="313" y="649.90372" width="262" height="16" fill="#f54ea2" />
    <circle cx="524" cy="388.80743" r="6" fill="#f54ea2" />
    <circle cx="545" cy="388.80743" r="6" fill="#f54ea2" />
    <circle cx="566" cy="388.80743" r="6" fill="#f54ea2" />
    <circle cx="524" cy="502.80743" r="6" fill="#f54ea2" />
    <circle cx="545" cy="502.80743" r="6" fill="#f54ea2" />
    <circle cx="566" cy="502.80743" r="6" fill="#f54ea2" />
    <circle cx="524" cy="616.80743" r="6" fill="#f54ea2" />
    <circle cx="545" cy="616.80743" r="6" fill="#f54ea2" />
    <circle cx="566" cy="616.80743" r="6" fill="#f54ea2" />
    <rect y="708.80704" width="888" height="2" fill="#3f3d56" />
    <path
      d="M397.8787,365.66116v185.9917a14.67525,14.67525,0,0,1-14.67227,14.67231H310.928a14.67656,14.67656,0,0,1-14.6784-14.67231V365.66116a14.67655,14.67655,0,0,1,14.6784-14.67228h8.76786a6.9778,6.9778,0,0,0,6.4551,9.60614h41.20843a6.97774,6.97774,0,0,0,6.45506-9.60614h9.392A14.67524,14.67524,0,0,1,397.8787,365.66116Z"
      transform="translate(-156 -94.59648)"
      fill="#e6e6e6"
    />
    <rect x="145.06414" y="291.91002" width="92" height="128" fill="#fff" />
    <rect
      x="171.18557"
      y="379.92719"
      width="36.71978"
      height="2.09827"
      fill="#e6e6e6"
    />
    <rect
      x="171.14869"
      y="387.63152"
      width="56.81724"
      height="2.09827"
      fill="#f54ea2"
    />
    <rect
      x="171.14869"
      y="394.89011"
      width="44.5883"
      height="2.09827"
      fill="#e6e6e6"
    />
    <rect
      x="171.14869"
      y="402.1487"
      width="26.22841"
      height="2.09827"
      fill="#e6e6e6"
    />
    <rect
      x="171.14869"
      y="409.40729"
      width="39.34262"
      height="2.09827"
      fill="#e6e6e6"
    />
    <rect
      x="154.53324"
      y="379.32379"
      width="4.19655"
      height="4.19655"
      fill="#e6e6e6"
    />
    <rect
      x="154.53324"
      y="386.58238"
      width="4.19655"
      height="4.19655"
      fill="#f54ea2"
    />
    <rect
      x="154.53324"
      y="393.84097"
      width="4.19655"
      height="4.19655"
      fill="#e6e6e6"
    />
    <rect
      x="154.53324"
      y="401.09956"
      width="4.19655"
      height="4.19655"
      fill="#e6e6e6"
    />
    <rect
      x="154.53324"
      y="408.35815"
      width="4.19655"
      height="4.19655"
      fill="#e6e6e6"
    />
    <rect
      x="172.75082"
      y="307.91002"
      width="36.99753"
      height="36.99753"
      fill="#e6e6e6"
    />
    <rect
      x="321.11058"
      y="0.13939"
      width="245.88383"
      height="157.47494"
      fill="#e6e6e6"
    />
    <rect
      x="339.64671"
      y="44.19534"
      width="203.86136"
      height="77.03517"
      fill="#fff"
    />
    <rect
      x="376.78939"
      y="57.54179"
      width="48.92684"
      height="2.79582"
      fill="#e6e6e6"
    />
    <rect
      x="376.74025"
      y="67.80733"
      width="75.70547"
      height="2.79582"
      fill="#f54ea2"
    />
    <rect
      x="376.74025"
      y="77.47895"
      width="59.41117"
      height="2.79582"
      fill="#e6e6e6"
    />
    <rect
      x="376.74025"
      y="87.15058"
      width="34.94775"
      height="2.79582"
      fill="#e6e6e6"
    />
    <rect
      x="376.74025"
      y="96.8222"
      width="52.42162"
      height="2.79582"
      fill="#e6e6e6"
    />
    <rect
      x="354.60119"
      y="56.7378"
      width="5.59164"
      height="5.59164"
      fill="#e6e6e6"
    />
    <rect
      x="354.60119"
      y="66.40942"
      width="5.59164"
      height="5.59164"
      fill="#f54ea2"
    />
    <rect
      x="354.60119"
      y="76.08104"
      width="5.59164"
      height="5.59164"
      fill="#e6e6e6"
    />
    <rect
      x="354.60119"
      y="85.75267"
      width="5.59164"
      height="5.59164"
      fill="#e6e6e6"
    />
    <rect
      x="354.60119"
      y="95.42429"
      width="5.59164"
      height="5.59164"
      fill="#e6e6e6"
    />
    <rect
      x="483.44507"
      y="53.8475"
      width="50.05873"
      height="50.05873"
      fill="#e6e6e6"
    />
    <rect x="321.00559" width="245.88383" height="10.44604" fill="#f54ea2" />
    <circle cx="328.76825" cy="5.34057" r="2.09686" fill="#fff" />
    <circle cx="336.11718" cy="5.34057" r="2.09686" fill="#fff" />
    <circle cx="343.46611" cy="5.34057" r="2.09686" fill="#fff" />
    <rect x="671.64772" y="294.80743" width="60" height="31" fill="#e6e6e6" />
    <rect x="671.64772" y="405.80743" width="60" height="31" fill="#e6e6e6" />
    <circle cx="701.64772" cy="365.80743" r="50" fill="#e6e6e6" />
    <circle cx="701.64772" cy="365.80743" r="39" fill="#fff" />
    <rect
      x="688.87659"
      y="355.12175"
      width="30.24683"
      height="1.72839"
      fill="#e2e2e2"
    />
    <rect
      x="688.84621"
      y="361.46796"
      width="46.80151"
      height="1.72839"
      fill="#f54ea2"
    />
    <rect
      x="688.84621"
      y="367.44701"
      width="36.72829"
      height="1.72839"
      fill="#e2e2e2"
    />
    <rect
      x="688.84621"
      y="373.42605"
      width="21.60488"
      height="1.72839"
      fill="#e2e2e2"
    />
    <rect
      x="688.84621"
      y="379.4051"
      width="32.40732"
      height="1.72839"
      fill="#e2e2e2"
    />
    <rect
      x="675.15972"
      y="354.62472"
      width="3.45678"
      height="3.45678"
      fill="#e2e2e2"
    />
    <rect
      x="675.15972"
      y="360.60376"
      width="3.45678"
      height="3.45678"
      fill="#f54ea2"
    />
    <rect
      x="675.15972"
      y="366.58281"
      width="3.45678"
      height="3.45678"
      fill="#e2e2e2"
    />
    <rect
      x="675.15972"
      y="372.56186"
      width="3.45678"
      height="3.45678"
      fill="#e2e2e2"
    />
    <rect
      x="675.15972"
      y="378.54091"
      width="3.45678"
      height="3.45678"
      fill="#e2e2e2"
    />
  </svg>
)

export default MaintenanceIcon
