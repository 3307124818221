import * as React from "react"

const Recruitment = () => {
  return (
    <ol className="pc:grid pc:grid-cols-2 pc:gap-16 pc:gap-y-28">
      <li>
        <p className="text-xl text-center text-brand-secondary font-bold poppins">
          01
        </p>
        <p className="mt-2 pc:text-xl sp:text-lg text-center font-bold">
          採用戦略構築
        </p>
        {/* <p className="mt-4 sp:text-sm">
          テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
        </p> */}
      </li>
      <li className="sp:mt-8">
        <p className="text-xl text-center text-brand-primary font-bold poppins">
          02
        </p>
        <p className="mt-2 pc:text-xl sp:text-lg text-center font-bold">
          採用プロセス構築
        </p>
      </li>
      <li className="sp:mt-8">
        <p className="text-xl text-center text-brand-secondary font-bold poppins">
          03
        </p>
        <p className="mt-2 pc:text-xl sp:text-lg text-center font-bold">
          採用媒体選定、原稿作成
        </p>
      </li>
      <li className="sp:mt-8">
        <p className="text-xl text-center text-brand-primary font-bold poppins">
          04
        </p>
        <p className="mt-2 pc:text-xl sp:text-lg text-center font-bold">
          採用媒体管理、メッセージ返信
        </p>
      </li>
      <li className="sp:mt-8">
        <p className="text-xl text-center text-brand-secondary font-bold poppins">
          05
        </p>
        <p className="mt-2 pc:text-xl sp:text-lg text-center font-bold">
          面接対応
        </p>
      </li>
      <li className="sp:mt-8">
        <p className="text-xl text-center text-brand-primary font-bold poppins">
          06
        </p>
        <p className="mt-2 pc:text-xl sp:text-lg text-center font-bold">
          内定者フォロー
        </p>
      </li>
      <li className="sp:mt-8">
        <p className="text-xl text-center text-brand-secondary font-bold poppins">
          07
        </p>
        <p className="mt-2 pc:text-xl sp:text-lg text-center font-bold">
          採用担当者育成
        </p>
      </li>
    </ol>
  )
}

export default Recruitment
